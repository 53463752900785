var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Simple"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSimple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.menu.open.apply(null, arguments);
      }
    }
  }, [_vm._v(" Right click on me ")]), _c('vue-context', {
    ref: "menu"
  }, [_c('li', _vm._l(_vm.menuData, function (data) {
    return _c('b-link', {
      key: data.text,
      staticClass: "d-flex align-items-center",
      on: {
        "click": function click($event) {
          return _vm.optionClicked(data.text, data.icon);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": data.icon,
        "size": "16"
      }
    }), _c('span', {
      staticClass: "ml-75"
    }, [_vm._v(_vm._s(data.text))])], 1);
  }), 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }